body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.landing-alert {
  margin-bottom: 0; 
}

.landing-section {
  padding: 100px 0;
  z-index: 1; 
}

.section-buttons {
  z-index: 1; 
}

.landing-section .btn-simple {
  padding: 0; 
}

.landing-section .column {
  padding: 0 75px 0 25px; 
}

.App {
  z-index: 1;
}

.page-header {
  background-image: linear-gradient(rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.3) 100%), url("../img/background.webp");
  opacity: 1;
  background-color: #B2AFAB;
  background-position: center center;
  background-size: cover;
  height: 620px;
  overflow: hidden;
  position: relative;
  width: 100%;
  justify-content: center; 
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 0;
}
  
.page-header .filter::after {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1; 
}

.page-header .brand {
  text-align: center;
  z-index: 3;
  color: #fff;
  position: relative; 
  margin: 50px 10% 50px 10%;
}

.page-header .logo {
  height: 60px;
  text-align: center;
}

.page-header .motto {
  text-align: center;
  z-index: 3;
  color: #fff;
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
}

.page-header .motto h1 {
  font-size: 30px;
  color: #fff;
}

.page-header .motto h3 {
  font-size: 16px;
  color: #fff;
}

.ShipWithUs:visited {
  border-radius: 0px;
  background-color: rgb(22, 88, 142, 0.8);
  font-weight: bold;
  color: #000;
  border: none;
}

.ShipWithUs, .ShipWithUs:link {
  border-radius: 0px;
  background-color: rgb(22, 88, 142);
  font-weight: bold;
  color: #000;
  z-index: 1000;
  border: none;
}

.ShipWithUs:hover, .ShipWithUs:active {
  border-radius: 0px;
  background-color: rgb(22, 88, 142, 0.8);
  font-weight: bold;
  color: #000;
  border: none;
}

.contact-form-button:visited {
  border-radius: 0px;
  background-color: rgb(22, 88, 142, 0.8);
  font-weight: bold;
  color: #000;
  border: none;
}

.contact-form-button, .contact-form-button:link {
  border-radius: 0px;
  background-color: rgb(22, 88, 142);
  font-weight: bold;
  color: #000;
  border: none;
}

.contact-form-button:hover, .contact-form-button:active {
  border-radius: 0px;
  background-color: rgb(22, 88, 142, 0.8);
  font-weight: bold;
  color: #000;
  border: none;
}

.center-text {
  text-align: center;
}

.section-header {
  padding-top: 50px;
  padding-bottom: 0px;
}

.section-subheader {
  padding-top: 20px;
  padding-bottom: 40px;
}

.section-divider {
  margin-top: 5px;
  margin-bottom: 24px;
}

.main {
  z-index: 20;
  background-color: #fff;
  display: block;
}

.social-media-icon {
  color: #fff;
}

.social-media-icon:hover {
  color: #ddd;
}

.link {
  color: #333 !important;
}

.link:hover {
  color: #555 !important;
}

.footer {
  background-color: #000000;
  color: #fff;
}

.hide {
  display: none;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .page-header .motto h1 {
    font-size: 36px;
    color: #fff;
  }
  
  .page-header .motto h3 {
    font-size: 20px;
    color: #fff;
  }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .page-header .motto h1 {
    font-size: 42px;
    color: #fff;
  }
  
  .page-header .motto h3 {
    font-size: 24px;
    color: #fff;
  }

  .page-header .motto {
    text-align: left;
    border-left: 2px white solid;
  }

  .page-header .logo {
    height: 70px;
  }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .page-header .motto h1 {
    font-size: 52px;
    color: #fff;
  }
  
  .page-header .motto h3 {
    font-size: 30px;
    color: #fff;
  }

  .page-header .motto {
    text-align: left;
    border-left: 2px white solid;
  }

  .page-header .logo {
    height: 80px;
  }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .page-header .motto h1 {
    font-size: 62px;
    color: #fff;
  }
  
  .page-header .motto h3 {
    font-size: 36px;
    color: #fff;
  }

  .page-header .motto {
    text-align: left;
    border-left: 2px white solid;
  }

  .page-header .logo {
    height: 100px;
  }
 }